<template>
  <v-card outlined>
    <v-card-text>
      <v-radio-group v-model="showFlag" row>
        노출 여부
        <v-radio class="ml-5" label="노출" :value="true" />
        <v-radio label="비노출" :value="false" />
      </v-radio-group>

      <v-list-item-title>질문</v-list-item-title>
      <v-text-field v-model="question" dense hide-details outlined class="mb-5" />

      <v-list-item-title>답변</v-list-item-title>
      <MarkdownEditor v-model="answer" height="500px" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn v-if="$route.params.uid" outlined color="#ffd600" @click="updateFaq($route.params.uid)">
        수정하기
      </v-btn>
      <v-btn v-else color="#ffd600" @click="createFaq">추가하기</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import MarkdownEditor from '@/components/MarkdownEditor.vue'

export default defineComponent({
  components: {
    MarkdownEditor,
  },

  setup(props, { root }) {
    const state = reactive({
      showFlag: true,
      question: null,
      answer: null,
    })

    const createFaq = async () => {
      await root.$store.dispatch('faq/createFaq', {
        question: state.question,
        answer: state.answer,
      })

      alert('추가 완료!')

      root.$router.push('/faq')
    }

    const getOneFaq = async (id) => {
      const faq = await root.$store.dispatch('faq/getOneFaq', {
        id: Number(id),
      })

      state.showFlag = faq.showFlag
      state.question = faq.question
      state.answer = faq.answer
    }

    const updateFaq = async (id) => {
      await root.$store.dispatch('faq/updateFaq', {
        id: Number(id),
        question: state.question,
        answer: state.answer,
        showFlag: state.showFlag,
      })

      alert('수정 완료!')

      root.$router.push('/faq')
    }

    onBeforeMount(() => {
      if (root.$route.params.uid) getOneFaq(root.$route.params.uid)
    })

    return {
      ...toRefs(state),
      createFaq,
      updateFaq,
    }
  },
})
</script>
